import { graphql } from "gatsby";
import PropTypes from "prop-types";
import * as React from "react";
import styled from "styled-components";

import GhostHead from "components/ghost/GhostHead";
import { Layout, TagTopicGrid } from "../components";
import { chunkAllPosts } from "../../../utils/chunkAllPosts";

const AuthorHeader = styled.section`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    max-width: 560px;
`;
const AuthorFigure = styled.figure`
    border-radius: 50%;
    height: 64px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    width: 64px;
    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
        display: block;
        max-width: 100%;
    }
    div {
        background-color: #e6e6e6;
    }
`;
const AuthorName = styled.h1`
    font-size: 2.4rem;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: #15171a;
    letter-spacing: -0.02em;
    line-height: 1.15;
`;
const AuthorBio = styled.div`
    font-family: Lora, Georgia, Times, serif;
    color: #999;
    font-size: 1.7rem;
    margin-top: 12px;
`;

/* eslint-disable react/prop-types */
export const Head = ({ data, location }) => {
    const metaProps = { data, location, type: "profile" };

    return <GhostHead metaProps={metaProps} />;
};
/* eslint-enable react/prop-types */

/**
 * Author page (/author/:slug)
 *
 * Loads all posts for the requested author incl. pagination.
 *
 */

const Author = ({ data, cardsLimit = 7 }) => {
    const author = data.ghostAuthor;
    const posts = data.allGhostPost.edges;
    const grids = chunkAllPosts(posts, cardsLimit);
    return (
        <Layout>
            <main className="site-main gh-main">
                <div className="container gh-inner">
                    <AuthorHeader>
                        <AuthorFigure>
                            <div>
                                <img
                                    src={
                                        author.profile_image ||
                                        "/icons/avatar.svg"
                                    }
                                    alt={author.name}
                                />
                            </div>
                        </AuthorFigure>
                        <div>
                            <AuthorName>{author.name}</AuthorName>
                            {author.bio && <AuthorBio>{author.bio}</AuthorBio>}
                        </div>
                    </AuthorHeader>
                    {grids.map((gird) => (
                        <article
                            key={gird}
                            data-sal="fade"
                            data-sal-delay="500"
                        >
                            <TagTopicGrid data={gird} />
                        </article>
                    ))}
                </div>
            </main>
        </Layout>
    );
};

Author.propTypes = {
    data: PropTypes.shape({
        ghostAuthor: PropTypes.shape({
            name: PropTypes.string.isRequired,
            cover_image: PropTypes.string,
            profile_image: PropTypes.string,
            website: PropTypes.string,
            bio: PropTypes.string,
            location: PropTypes.string,
            facebook: PropTypes.string,
            twitter: PropTypes.string,
        }),
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    cardsLimit: PropTypes.number,
};

export default Author;

export const pageQuery = graphql`
    query GhostAuthorQuery($slug: String!) {
        ghostAuthor(slug: { eq: $slug }) {
            ...GhostAuthorFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] }
            filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
